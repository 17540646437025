var OwlCarousel = function () {
	return {
		initOwlCarousel: function () {
			$('.testimonials-v6').owlCarousel({
        loop: true,
        margin: 0,
        responsive: {
          0:{
            items: 1
          }
        },
        // navText: [,],
        // nav: true,
        // dots: false,
				autoplay:true,
				dots: true,
				dotsClass: 'owl-pagination',
				dotClass: 'owl-page',
			});

			$('.owl-clients-v5').owlCarousel({
        loop: true,
        margin: 20,
        responsive: {
					300:{
            items: 1
          },
          400:{
            items: 2
          },
          500:{
            items: 3
          },
          600:{
            items: 4
          },
          800:{
            items: 5
          },
          900:{
            items: 3
          },
          1000:{
            items: 3
          },
          1150:{
            items: 4
          },
          1300:{
            items: 5
          }
        },
        // nav: false,
        // dots: false,
				autoplay:true,
				dots: true,
				dotsClass: 'owl-pagination',
				dotClass: 'owl-page',
				slideBy: 'page'
			});

			//Owl Clients v1
			$(".owl-clients-v1").owlCarousel({
					items: 7,
					autoplay: true,
					loop: true,
					responsive: {
						300:{
							items: 2
						},
						600:{
							items: 3
						},
						900:{
							items: 4
						},
						1000:{
							items: 5
						},
	          1200:{
	            items: 7
	          }
					},
			});
		}
	};
}();
