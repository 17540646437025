// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('unify', true, /\.(png|jpe?g|svg)$/)
const imagePath = (name) => images(name, true)

// <!-- CSS Global Compulsory -->
require("unify/One-Pages/assets/plugins/bootstrap/css/bootstrap.min.css")
require("unify/One-Pages/assets/css/app.css")
require("unify/One-Pages/assets/css/blocks.css")
require("unify/One-Pages/assets/css/one.style.css")

// <!-- CSS Footer -->

// <!-- CSS Implementing Plugins -->
require("unify/One-Pages/assets/plugins/animate.css")
require("unify/One-Pages/assets/plugins/line-icons/line-icons.css")
require("unify/One-Pages/assets/plugins/font-awesome/css/font-awesome.min.css")
require("unify/One-Pages/assets/plugins/owl-carousel2/assets/owl.carousel.css")
require("unify/One-Pages/assets/plugins/master-slider/masterslider/style/masterslider.css")
require("unify/One-Pages/assets/plugins/master-slider/masterslider/skins/black-2/style.css")
require("unify/One-Pages/assets/plugins/cube-portfolio/cubeportfolio/css/cubeportfolio.min.css")
require("unify/One-Pages/assets/plugins/cube-portfolio/cubeportfolio/custom/custom-cubeportfolio.css")
require("unify/One-Pages/assets/plugins/hover-effects/css/custom-hover-effects.css")
require("unify/One-Pages/assets/plugins/sky-forms-pro/skyforms/css/sky-forms.css")
require("unify/One-Pages/assets/plugins/sky-forms-pro/skyforms/custom/custom-sky-forms.css")
require("unify/One-Pages/assets/plugins/pace/pace-flash.css")

// <!-- CSS Theme -->
require("unify/One-Pages/Courses/assets/css/courses.style.css")
require("unify/One-Pages/Courses/assets/css/global.css")

// <!-- CSS Customization -->
require("unify/One-Pages/Courses/assets/css/custom.css")


// <!-- JS Global Compulsory -->
window.jQuery = window.$ = require("unify/One-Pages/assets/plugins/jquery/jquery.min.js")
require("unify/One-Pages/assets/plugins/jquery/jquery-migrate.min.js")
require("unify/One-Pages/assets/plugins/bootstrap/js/bootstrap.min.js")

// <!-- JS Implementing Plugins -->
require("unify/One-Pages/assets/plugins/smoothScroll.js")
require("unify/One-Pages/assets/plugins/master-slider/masterslider/masterslider.min.js")
require("unify/One-Pages/assets/plugins/jquery.easing.min.js")
require("unify/One-Pages/assets/plugins/pace/pace.min.js")
require("unify/One-Pages/assets/plugins/owl-carousel2/owl.carousel.min.js")
require("imports-loader?wrapper=window&additionalCode=var%20define%20=%20false;!unify/One-Pages/assets/plugins/counter/waypoints.min.js")
require("unify/One-Pages/assets/plugins/counter/jquery.counterup.min.js")
require("unify/One-Pages/assets/plugins/circles-master/circles.min.js")
require("unify/One-Pages/assets/plugins/sky-forms-pro/skyforms/js/jquery.form.min.js")
require("unify/One-Pages/assets/plugins/sky-forms-pro/skyforms/js/jquery.validate.min.js")
require("unify/One-Pages/assets/plugins/cube-portfolio/cubeportfolio/js/jquery.cubeportfolio.min.js")
require("imports-loader?wrapper=window!unify/One-Pages/assets/plugins/modernizr.js")

// <!-- JS Page Level-->
window.App = require("exports-loader?type=commonjs&exports=single|App!unify/One-Pages/assets/js/one.app.js")
window.MSfullWidth = require("exports-loader?type=commonjs&exports=single|MSfullWidth!unify/One-Pages/assets/js/master-slider-fw.js")
window.Owl2Carouselv1 = require("exports-loader?type=commonjs&exports=single|Owl2Carouselv1!unify/One-Pages/Courses/assets/js/plugins/owl2-carousel-v1.js")
window.Owl2Carouselv2 = require("exports-loader?type=commonjs&exports=single|Owl2Carouselv2!unify/One-Pages/Courses/assets/js/plugins/owl2-carousel-v2.js")
window.Owl2Carouselv3 = require("exports-loader?type=commonjs&exports=single|Owl2Carouselv3!unify/One-Pages/Courses/assets/js/plugins/owl2-carousel-v3.js")
window.Owl2Carouselv4 = require("exports-loader?type=commonjs&exports=single|Owl2Carouselv4!unify/One-Pages/Courses/assets/js/plugins/owl2-carousel-v4.js")
window.Owl2Carouselv5 = require("exports-loader?type=commonjs&exports=single|Owl2Carouselv5!unify/One-Pages/Courses/assets/js/plugins/owl2-carousel-v5.js")
window.OwlCarousel = require("exports-loader?type=commonjs&exports=single|OwlCarousel!unify/One-Pages/Courses/assets/js/plugins/owl-carousel2.js")
window.CubePortfolio  = require("exports-loader?type=commonjs&exports=single|CubePortfolio!unify/One-Pages/Courses/assets/js/plugins/cube-portfolio.js")
// window.initMap 				= require("exports-loader?type=commonjs&exports=single|initMap!unify/One-Pages/Courses/assets/js/plugins/gmaps-ini.js")
window.ContactForm 		= require("exports-loader?type=commonjs&exports=single|ContactForm!unify/One-Pages/Courses/assets/js/forms/contact.js")

$(function() {
	App.init();
	App.initCounter();
	MSfullWidth.initMSfullWidth();
	Owl2Carouselv1.initOwl2Carouselv1();
	Owl2Carouselv2.initOwl2Carouselv2();
	Owl2Carouselv3.initOwl2Carouselv3();
	Owl2Carouselv4.initOwl2Carouselv4();
	Owl2Carouselv5.initOwl2Carouselv5();
	OwlCarousel.initOwlCarousel();
	CubePortfolio.initCubePortfolio();
	ContactForm.initContactForm();
});
// TODO add this code
// <!--[if lt IE 10]>
//   <script src="../assets/plugins/sky-forms-pro/skyforms/js/jquery.placeholder.min.js"></script>
// <![endif]-->
